.notification{
  border-color: var(--clr-bg-white);
  background: var(--clr-bg-white);
  box-shadow: 0 30px 50px rgba(28, 52, 94, .1);
  transition: all 0.5s;
  border-radius: 8px;
  min-width: 240px;
  z-index: 1000;
  position: absolute;
  top: 0px;
  right: -400px;
  padding: 16px;
  background: #fff;
  color: #181833;
  border-top: 7px solid #fd3566;
}

.notification-body {
  font-size: 14px;
}

.notification.shown{
  right: 20px;
}